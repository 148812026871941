import { currencyIcon } from 'constants/currencies';

import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button, DatePicker, Radio, Space, Table, Typography } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { getAnalytics } from 'api/products';
import { IAnalyticsProducts, TAnalyticsProducts } from 'api/types/analytics';
import { ECurrency } from 'api/types/enums';
import dayjs from 'dayjs';
import { useTableHeight } from 'hooks/useTableHeight';

import styles from './analytics-tables.module.scss';

const { Text } = Typography;
const { RangePicker } = DatePicker;

const disabledDate = (current: unknown) =>
  new Date(current as Date).getTime() >= Date.now();

export const ProductsTable = (props: { products: IAnalyticsProducts }) => {
  const [products, setProducts] = useState(props.products);
  const [searchParams, setSearchParams] = useSearchParams();

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);

  const tableHeight = useTableHeight(tableContainerRef, tableHeadRef);

  const onDateChange = async (_: any, dateStrings: [string, string]) => {
    setSearchParams({
      productsFrom: dateStrings[0],
      productsTo: dateStrings[1],
    });

    const filteredOrders = await getAnalytics({
      from: dateStrings[0],
      to: dateStrings[1],
    });

    setProducts(filteredOrders.data.data);
  };

  const columns: ColumnsType<TAnalyticsProducts> = [
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
    },
    {
      title: 'Вид валюты',
      dataIndex: 'currencyName',
      key: 'currencyName',
      filters: [
        {
          text: 'Алмазы',
          value: 'diamonds',
        },
        {
          text: 'Джинкоины',
          value: 'coins',
        },
      ],
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <Space direction="vertical" style={{ padding: 8 }}>
          <Radio.Group
            onChange={(e) => setSelectedKeys([e.target.value])}
            value={selectedKeys[0]}
          >
            <Space direction="vertical">
              <Radio value="diamonds">Алмазы {currencyIcon.diamonds}</Radio>
              <Radio value="coins">Джинкоины {currencyIcon.coins}</Radio>
            </Space>
          </Radio.Group>
          <Space>
            <Button
              size="small"
              onClick={() => clearFilters?.()}
              disabled={!selectedKeys[0]}
            >
              Сбросить
            </Button>
            <Button size="small" type="primary" onClick={() => confirm()}>
              Применить
            </Button>
          </Space>
        </Space>
      ),
      render: (value) => (
        <Text>{value === 'diamonds' ? 'Алмазы' : 'Джинкоины'}</Text>
      ),
      width: '20%',
    },
    {
      title: 'Количество заказов',
      dataIndex: 'ordersCount',
      key: 'ordersCount',
      width: '20%',
    },
    {
      title: 'Общая сумма',
      dataIndex: 'ordersSum',
      key: 'ordersSum',
      width: '20%',
    },
  ];

  const handleFiltersChange: TableProps<TAnalyticsProducts>['onChange'] =
    async (_, filters) => {
      const filteredOrders = await getAnalytics({
        from: searchParams.get('productsFrom') ?? null,
        to: searchParams.get('productsTo') ?? null,
        currency: filters.currencyName?.[0] as ECurrency,
      });

      setProducts(filteredOrders.data.data);
    };

  return (
    <div className={styles.page}>
      <div className={styles.heading}>
        <RangePicker
          value={[
            searchParams.get('productsFrom')
              ? dayjs(searchParams.get('productsFrom'))
              : null,
            searchParams.get('productsTo')
              ? dayjs(searchParams.get('productsTo'))
              : null,
          ]}
          disabledDate={disabledDate}
          onChange={onDateChange}
        />
        <Text className={styles.total}>
          Общая стоимость: <Text strong>{products.totalCoinSum}</Text>{' '}
          {currencyIcon.coins}
        </Text>
        <Text className={styles.total}>
          Общая стоимость: <Text strong>{products.totalDiamondSum}</Text>{' '}
          {currencyIcon.diamonds}
        </Text>
      </div>
      <div className={styles.tableContent} ref={tableContainerRef}>
        <Table
          className={styles.table}
          bordered
          rowKey="id"
          pagination={false}
          columns={columns}
          dataSource={products.products}
          onChange={handleFiltersChange}
          scroll={{
            y: tableHeight,
            x: 'max-content',
          }}
          components={{
            header: {
              wrapper: (props: any) => <thead {...props} ref={tableHeadRef} />,
            },
          }}
        />
      </div>
    </div>
  );
};
