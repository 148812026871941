import { FC, Fragment, PropsWithChildren, ReactNode } from 'react';

import Title from 'antd/es/typography/Title';

import styles from './page-layout.module.scss';

interface IPageLayoutProps {
  title: ReactNode | string;
  actions?: ReactNode[];
}

export const PageLayout: FC<PropsWithChildren<IPageLayoutProps>> = ({
  children,
  title,
  actions,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.header}>
      <Title className={styles.heading}>{title}</Title>
    </div>
    <div className={styles.body}>
      {actions && (
        <div className={styles.tableHeader}>
          {actions.map((action, index) => (
            <Fragment key={index}>{action}</Fragment>
          ))}
        </div>
      )}
      {children}
    </div>
  </div>
);
