import { useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import { Space, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import {
  IAnalyticsBalance,
  IAnalyticsOrders,
  IAnalyticsProducts,
} from 'api/types/analytics';
import { BalanceTable } from 'components/tables/analytics/balance-table';
import { OrdersTable } from 'components/tables/analytics/orders-table';
import { ProductsTable } from 'components/tables/analytics/products-table';
import { PageLayout } from 'layouts/page';

const tabs = [
  {
    key: 'balance',
    label: 'Баланс',
  },
  {
    key: 'orders',
    label: 'Заказы',
  },
  {
    key: 'goods',
    label: 'Товары',
  },
];

export const AnalyticsPage = () => {
  const { orders, products, balance } = useLoaderData() as {
    orders: IAnalyticsOrders;
    balance: IAnalyticsBalance;
    products: IAnalyticsProducts;
  };

  const [activeTab, setActiveTab] = useState(tabs[0].key);

  const handleTabsChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <PageLayout title="Аналитика">
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        onChange={handleTabsChange}
        indicatorSize={(origin) => origin - 16}
      >
        {tabs.map(({ key, label }) => (
          <TabPane
            tab={
              <Space size="small" align="start">
                {label}
              </Space>
            }
            key={key}
          />
        ))}
      </Tabs>
      {activeTab === 'balance' && <BalanceTable balance={balance} />}
      {activeTab === 'orders' && <OrdersTable orders={orders} />}
      {activeTab === 'goods' && <ProductsTable products={products} />}
    </PageLayout>
  );
};
