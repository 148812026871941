import { ID, IDataResponse, IPagginatedDataResponse } from 'api/types/common';
import { ECurrency } from 'api/types/enums';
import { IProduct } from 'api/types/products';
import { AxiosResponse } from 'axios';

import { $host } from '..';

export const getProducts = (): Promise<
  AxiosResponse<IDataResponse<IPagginatedDataResponse<IProduct[]>>>
> => $host.get('/admin/products');

export const postProduct = (
  data: Omit<IProduct, 'id' | 'currency' | 'category'>
): Promise<AxiosResponse<IDataResponse<IProduct>>> =>
  $host.post('/admin/products', { ...data });

export const updateProduct = (
  data: Omit<IProduct, 'id' | 'currency' | 'category'>,
  id: ID
): Promise<AxiosResponse<IDataResponse<IProduct>>> =>
  $host.patch(`/admin/products/${id}`, { ...data });

export const deleteProduct = (
  id: ID
): Promise<AxiosResponse<IDataResponse<IProduct>>> =>
  $host.delete(`/admin/products/${id}`);

export const reorderProduct = (
  data: Pick<IProduct, 'name'>[]
): Promise<AxiosResponse<IDataResponse<IPagginatedDataResponse<IProduct[]>>>> =>
  $host.put('/admin/products/order', { orders: data });

export const getAnalytics = ({
  from,
  to,
  currency,
}: {
  from: string | null;
  to: string | null;
  currency?: ECurrency;
}) =>
  $host.get('/admin/products/analytics', {
    params: {
      from,
      to,
      currency,
    },
  });
