import { IDataResponse } from 'api/types/common';
import { AxiosResponse } from 'axios';

import { $host } from '..';
import {
  IAdminUser,
  TCreateAdminUserPayload,
  TDeleteAdminUserPayload,
  IRatingPayload,
} from '../types/admin-users';

export const getAdminUsers = (): Promise<
  AxiosResponse<IDataResponse<IAdminUser[]>>
> => $host.get('/admin-users');

export const postAdminUser = (
  values: TCreateAdminUserPayload
): Promise<AxiosResponse<IDataResponse<IAdminUser>>> =>
  $host.post('/admin-users', { ...values });

export const postHrUser = (
  values: TCreateAdminUserPayload
): Promise<AxiosResponse<IDataResponse<IAdminUser>>> =>
  $host.post('/admin-users/hr', {
    ...values,
  });

export const deleteAdminUser = (values: TDeleteAdminUserPayload) =>
  $host.delete(`/admin-users/admins/${values.id}`);

export const deleteHrUser = (values: TDeleteAdminUserPayload) =>
  $host.delete(`/admin-users/hr/${values.id}`);

export const sendTestRating = (data: IRatingPayload) =>
  $host.post('/admin-users/rating/test', { ...data });

export const sendRatingToAll = (data: IRatingPayload) =>
  $host.post('/admin-users/rating', { ...data });

export const getAdminUserBalanceAnalytics = () =>
  $host.get(`/admin-users/analytics/balances`);
