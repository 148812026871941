import { currencyIcon } from 'constants/currencies';

import { useRef } from 'react';

import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IAnalyticsBalance, TAnalitycsBalanceUser } from 'api/types/analytics';
import { useTableHeight } from 'hooks/useTableHeight';

import styles from './analytics-tables.module.scss';

const { Text } = Typography;

export const BalanceTable = ({ balance }: { balance: IAnalyticsBalance }) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);

  const tableHeight = useTableHeight(tableContainerRef, tableHeadRef);

  const columns: ColumnsType<TAnalitycsBalanceUser> = [
    {
      title: 'Пользователь',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Количество джинкоинов',
      dataIndex: 'balanceCoins',
      key: 'balanceCoins',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => a.balanceCoins - b.balanceCoins,
      },
    },
    {
      title: 'Количество алмазов',
      dataIndex: 'balanceDiamonds',
      key: 'balanceDiamonds',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => a.balanceDiamonds - b.balanceDiamonds,
      },
    },
  ];

  return (
    <div className={styles.page}>
      <div className={styles.heading}>
        <Text className={styles.total}>
          Всего джинкоинов {currencyIcon.coins}:{' '}
          <Text strong>{balance.totalCoins}</Text>
        </Text>
        <Text className={styles.total}>
          Всего алмазов {currencyIcon.diamonds}:{' '}
          <Text strong>{balance.totalDiamonds}</Text>
        </Text>
      </div>
      <div className={styles.tableContent} ref={tableContainerRef}>
        <Table
          className={styles.table}
          bordered
          rowKey="id"
          pagination={false}
          columns={columns}
          dataSource={balance.users}
          scroll={{
            y: tableHeight,
            x: 'max-content',
          }}
          components={{
            header: {
              wrapper: (props: any) => <thead {...props} ref={tableHeadRef} />,
            },
          }}
        />
      </div>
    </div>
  );
};
