import { Navigate, RouteObject, redirect } from 'react-router-dom';

import { ERole } from 'api/types/enums';
import { IAuth, useApp } from 'lib/app';
import { useRouteWrapper } from 'lib/role-wrapper';
import { configureLoaders, configureActions } from 'utils';

import {
  AdminsPage,
  loaders as adminLoaders,
  actions as adminActions,
} from './admins';
import { AnalyticsPage, loaders as analyticsLoaders } from './analytics';
import {
  CatogoriesPage,
  loaders as categoriesLoaders,
  actions as categoriesActions,
} from './categories';
import { LoginPage } from './login';
import {
  MessagesPage,
  loaders as messagesLoaders,
  actions as messagesActions,
} from './messages';
import { NewsletterPage, actions as newsletterActions } from './newsletter';
import { OrderPage, loaders as orderLoaders } from './order';
import {
  OrdersPage,
  loaders as ordersLoaders,
  actions as ordersActions,
} from './orders';
import { RatingsPage, actions as ratingsActions } from './ratings';
import {
  ShopPage,
  loaders as productsLoaders,
  actions as productsActions,
} from './shop';
import {
  TransactionsPage,
  loaders as transactionLoaders,
} from './transactions';
import {
  UserPage,
  loaders as userLoaders,
  actions as userActions,
} from './user';
import {
  UsersPage,
  loaders as usersLoaders,
  actions as usersActions,
} from './users';

export const MainRoutes = (): RouteObject[] | undefined => {
  const { loading, auth } = useApp();

  const routes: RouteObject[] = [
    {
      path: '/',
      loader: () => redirect('/users'),
    },
    {
      path: '/users',
      loader: configureLoaders(usersLoaders),
      action: configureActions(usersActions),
      element: <UsersPage />,
    },
    {
      path: '/users/:id',
      loader: configureLoaders<IAuth>(userLoaders, auth),
      action: configureActions(userActions),
      element: <UserPage />,
    },
    useRouteWrapper([ERole.hr], {
      path: '/analitics',
      loader: configureLoaders(analyticsLoaders),
      element: <AnalyticsPage />,
    }) ?? {},
    useRouteWrapper([ERole.hr], {
      path: '/transactions',
      loader: configureLoaders(transactionLoaders),
      element: <TransactionsPage />,
    }) ?? {},
    useRouteWrapper([ERole.hr], {
      path: '/categories',
      loader: configureLoaders(categoriesLoaders),
      action: configureActions(categoriesActions),
      element: <CatogoriesPage />,
    }) ?? {},
    useRouteWrapper([ERole.hr], {
      path: '/shop',
      loader: configureLoaders(productsLoaders),
      action: configureActions(productsActions),
      element: <ShopPage />,
    }) ?? {},
    useRouteWrapper([ERole.hr], {
      path: '/orders',
      action: configureActions(ordersActions),
      loader: configureLoaders(ordersLoaders),
      element: <OrdersPage />,
    }) ?? {},
    useRouteWrapper([ERole.hr], {
      path: '/orders/:id',
      loader: configureLoaders(orderLoaders),
      element: <OrderPage />,
    }) ?? {},
    useRouteWrapper([ERole.hr], {
      path: '/messages',
      element: <MessagesPage />,
      loader: configureLoaders(messagesLoaders),
      action: configureActions(messagesActions),
    }) ?? {},
    useRouteWrapper([ERole.hr], {
      path: '/newsletter',
      action: configureActions(newsletterActions),
      element: <NewsletterPage />,
    }) ?? {},
    useRouteWrapper([ERole.hr], {
      path: '/ratings',
      action: configureActions(ratingsActions),
      element: <RatingsPage />,
    }) ?? {},
    {
      path: '/login',
      element: <LoginPage />,
    },
    useRouteWrapper([ERole.hr], {
      path: '/admins',
      loader: configureLoaders(adminLoaders),
      action: configureActions(adminActions),
      element: <AdminsPage />,
    }) ?? {},
    {
      path: '*',
      element: <Navigate to="/" />,
    },
  ];

  if (loading) return;

  return routes;
};
