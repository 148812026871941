import { getActiveUsersCount, getUsers } from 'api/users';

export const loaders = () => [
  {
    name: 'users',
    fn: getUsers,
  },
  {
    name: 'activeUsers',
    fn: getActiveUsersCount,
  },
];
