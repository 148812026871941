import { currencyIcon } from 'constants/currencies';

import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Table, Typography, DatePicker } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { getAnalytics } from 'api/orders';
import { IAnalyticsOrders, TAnalyticsOrdersData } from 'api/types/analytics';
import dayjs from 'dayjs';
import { useTableHeight } from 'hooks/useTableHeight';

import styles from './analytics-tables.module.scss';

const { Text } = Typography;

const { RangePicker } = DatePicker;

const disabledDate = (current: unknown) =>
  new Date(current as Date).getTime() >= Date.now();

export const OrdersTable = (props: { orders: IAnalyticsOrders }) => {
  const [orders, setOrders] = useState(props.orders);
  const [searchParams, setSearchParams] = useSearchParams();

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);

  const tableHeight = useTableHeight(tableContainerRef, tableHeadRef);

  const onDateChange = async (_: any, dateStrings: [string, string]) => {
    setSearchParams({
      ordersFrom: dateStrings[0],
      ordersTo: dateStrings[1],
    });

    const filteredOrders = await getAnalytics({
      from: dateStrings[0],
      to: dateStrings[1],
    });

    setOrders(filteredOrders.data.data);
  };

  const columns: ColumnsType<TAnalyticsOrdersData> = [
    {
      title: 'Пользователь',
      dataIndex: 'fullName',
      key: 'fullName',
      width: '20%',
    },
    {
      title: 'Количество заказов за джинкойны',
      dataIndex: 'coinOrdersCount',
      key: 'coinOrdersCount',
      width: '20%',
      defaultSortOrder: 'descend',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => a.coinOrdersCount - b.coinOrdersCount,
      },
    },
    {
      title: 'Сумма за заказы',
      dataIndex: 'coinOrdersSum',
      key: 'coinOrdersSum',
      width: '20%',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => a.coinOrdersSum - b.coinOrdersSum,
      },
    },
    {
      title: 'Количество заказов за алмазы',
      dataIndex: 'diamondOrdersCount',
      key: 'diamondOrdersCount',
      width: '20%',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => a.diamondOrdersCount - b.diamondOrdersCount,
      },
    },
    {
      title: 'Сумма за заказы',
      dataIndex: 'diamondOrdersSum',
      key: 'diamondOrdersSum',
      width: '20%',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => a.diamondOrdersSum - b.diamondOrdersSum,
      },
    },
  ];

  return (
    <div className={styles.page}>
      <div className={styles.heading}>
        <RangePicker
          value={[
            searchParams.get('ordersFrom')
              ? dayjs(searchParams.get('ordersFrom'))
              : null,
            searchParams.get('ordersTo')
              ? dayjs(searchParams.get('ordersTo'))
              : null,
          ]}
          disabledDate={disabledDate}
          onChange={onDateChange}
        />
        <Text className={styles.total}>
          Всего заказов за джинкойны {currencyIcon.coins}:{' '}
          <Text strong>{orders.totalCoinCount}</Text>
        </Text>
        <Text className={styles.total}>
          Общая стоимость: <Text strong>{orders.totalCoinSum}</Text>{' '}
          {currencyIcon.coins}
        </Text>
        <Text className={styles.total}>
          Всего заказов за алмазы {currencyIcon.diamonds}:{' '}
          <Text strong>{orders.totalDiamondCount}</Text>
        </Text>
        <Text className={styles.total}>
          Общая стоимость: <Text strong>{orders.totalDiamondSum}</Text>{' '}
          {currencyIcon.diamonds}
        </Text>
      </div>
      <div className={styles.tableContent} ref={tableContainerRef}>
        <Table
          className={styles.table}
          bordered
          rowKey="id"
          pagination={false}
          columns={columns}
          dataSource={orders.orders}
          scroll={{
            y: tableHeight,
            x: 'max-content',
          }}
          components={{
            header: {
              wrapper: (props: any) => <thead {...props} ref={tableHeadRef} />,
            },
          }}
        />
      </div>
    </div>
  );
};
