import { LoaderFunctionArgs } from 'react-router-dom';

import { getAdminUserBalanceAnalytics } from 'api/admin-users';
import { getAnalytics as getOrdersAnalitycs } from 'api/orders';
import { getAnalytics as getProductsAnalitycs } from 'api/products';
import { IAnalyticsOrders, IAnalyticsProducts } from 'api/types/analytics';
import { AnyType, IDataResponse } from 'api/types/common';

export const getMockAxiosResponse = <T>(data: IDataResponse<T>) =>
  Promise.resolve({
    data,
    status: 200,
    statusText: 'ok',
    headers: {} as AnyType,
    config: {} as AnyType,
  });

export const loaders = ({ request }: LoaderFunctionArgs<AnyType>) => [
  {
    name: 'balance',
    fn: getAdminUserBalanceAnalytics,
  },
  {
    name: 'orders',
    fn: async () => {
      const from = new URL(request.url).searchParams.get('ordersFrom');
      const to = new URL(request.url).searchParams.get('ordersTo');

      if (from && to) {
        return await getOrdersAnalitycs({ from, to });
      } else {
        return getMockAxiosResponse<IAnalyticsOrders>({
          data: {
            orders: [],
            totalCoinCount: 0,
            totalCoinSum: 0,
            totalDiamondCount: 0,
            totalDiamondSum: 0,
          },
          statusCode: 200,
          timestamp: new Date().toISOString(),
        });
      }
    },
  },
  {
    name: 'products',
    fn: async () => {
      const from = new URL(request.url).searchParams.get('productsFrom');
      const to = new URL(request.url).searchParams.get('productsTo');

      if (from && to) {
        return await getProductsAnalitycs({ from, to });
      } else {
        return getMockAxiosResponse<IAnalyticsProducts>({
          data: {
            products: [],
            totalCoinSum: 0,
            totalDiamondSum: 0,
          },
          statusCode: 200,
          timestamp: new Date().toISOString(),
        });
      }
    },
  },
];
